<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img
        :src="appLogoImage"
        class="logo-content"
        alt="logo"
      />
      <h2 class="brand-text mb-0">
        {{ appName }}
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Logging Out, please wait.
        </h2>
        <b-img
          fluid
          :src="imgUrl"
          alt="Not authorized page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import store from '@/store/index'
import { initialAbility } from '@/libs/acl/config'

export default {
  components: {
    BLink, BImg,
  },
  data() {
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
      downImg: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  created() {
    this.logout()
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('userRole')
      localStorage.removeItem('userToken')
      localStorage.removeItem('roleId')
      localStorage.removeItem('tahunAnggaran')
      localStorage.removeItem('master_jenis_belanja_id')
      localStorage.removeItem('master_jenis_pengadaan_id')
      localStorage.removeItem('master_sumber_dana_id')
      localStorage.removeItem('master_instansi_id')
      localStorage.removeItem('prefix')
      localStorage.removeItem('nama_kegiatan')
      localStorage.removeItem('nama_app_infra')
      localStorage.removeItem('searchTerm')

      // Reset ability
      this.$ability.update(initialAbility)

      setTimeout(() => {
        // Redirect to login page
        this.$router.push({ name: 'auth-login' })
      }, 1000)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
